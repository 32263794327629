:root {
  --primary-color: #009ace;
}

html {
  @apply min-h-full;
}

body {
  @apply h-full min-h-screen;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

body {
  @apply flex flex-col;
}

main {
  flex: 1 0 auto;
}

h1 {
  @apply text-3xl font-bold text-gray-800 tracking-tight md:text-4xl leading-tight;
}
h2 {
  @apply text-2xl font-bold text-gray-800 tracking-tight md:text-3xl leading-tight;
}
h3 {
  @apply text-xl font-bold text-gray-800 tracking-tight md:text-2xl leading-tight;
}

hr {
  @apply my-1 border-gray-300;
}

/* Image lazyloading background */
img[loading],
img.loading {
  background: linear-gradient(to bottom right, theme('colors.blue-gray.100') 40%, theme('colors.blue-gray.200') 50%, theme('colors.blue-gray.100') 60%);
  animation: loadingBg 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-size: 300% 300%;
}

@keyframes loadingBg {
  0% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

#header-banner {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: inline;
    margin-right: 5px;
  }
}

figcaption {
  hyphens: auto;
  word-break: break-word;
}

.pdf-viewer, #pdf-fallback {
  flex-grow: 1;
}
#pdf-fallback {
  display: flex;
}

[href="/friseur/backend/login"] {
  display: none;
}

.restricted-height .bg-image {
    @media screen and (max-width: 480px){
      margin-top: 65px;
    }
  }

