:root {
  --editor-color-orange: #ce4100;
  --editor-color-yellow: #cea800;
  --editor-color-purple: #8200ce;
  --editor-color-green: #00cc52;
  --editor-color-blue: var(--primary-color);
}

.editor-color-orange {
  p, ul, strong {
    color: var(--editor-color-orange) !important;
  }
}

.editor-color-yellow {
  p, ul, strong {
    color: var(--editor-color-yellow) !important;;
  }
}

.editor-color-purple {
  p, ul, strong {
    color: var(--editor-color-purple) !important;;
  }
}

.editor-color-blue {
  p, ul, strong {
    color: var(--editor-color-blue) !important;;
  }
}

.editor-color-green {
  p, ul, strong {
    color: var(--editor-color-green) !important;;
  }

}
