.horizontal-media-scroller {
  --size: calc(100vw / 2.64);
  --gap: 2.5rem;
  --outer-gap: 1.25rem;
  --border-radius: 0.5rem;

  /* Tablet size */
  @screen md {
    --size: calc(100vw / 3.7);
    --gap: 4rem;
    --outer-gap: 3rem;
  }

  @apply grid grid-flow-col;

  grid-auto-columns: var(--size);
  gap: calc(var(--gap) / 2);
  padding-top: calc(var(--outer-gap) / 2.5);
  padding-bottom: calc(var(--outer-gap) / 1.25);
  padding-left: calc(var(--gap) / 2);

  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  /* scroll-padding-inline: calc(var(--gap) / 2); */
  scroll-padding-left: calc(var(--gap) / 2);
  scroll-padding-right: calc(var(--gap) / 2);
  outline-offset: -5px;

  & > a {
    text-decoration: none;
    color: inherit;
  }
}

.horizontal-media-scroller figure {
  @apply grid m-0 relative select-none;

  scroll-snap-align: start;

  &:last-of-type::after,
  &:first-of-type::after {
    @apply h-full absolute;

    content: '';
    width: var(--outer-gap);
    inline-size: var(--outer-gap);
    block-size: 100%;
    left: 0;
    right: calc(var(--outer-gap) * -1);
  }

  &:first-of-type::after {
    left: auto;
    right: calc(var(--outer-gap) * -1);
  }

  & > picture {
    @apply inline-block;
    overflow: hidden;
    border-radius: var(--border-radius);

    & > img {
      inline-size: 100%;
      block-size: 100%;
      object-fit: cover;
    }
  }
}

/* Modifiers */

/* Large figure */
.horizontal-media-scroller.--large {
  --size: calc(100vw / 2.05);
  --border-radius: 1rem;
  padding-bottom: 2rem;

  /* Tablet size */
  @screen md {
    --size: calc(100vw / 2.7);
  }

  &.--shadow {
    padding-bottom: 2.22rem;
    figure {
      @apply shadow-xl;
    }
  }
}

/* with shadow */
.horizontal-media-scroller.--shadow {
  padding-bottom: 1.25rem;
  figure {
    @apply shadow-lg;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  &.--caption figure {
    & > picture {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    figcaption {
      @apply pt-2 pb-3 px-4 md:pt-3 md:pb-4;
    }
  }
}

/* with caption */
.horizontal-media-scroller.--caption {
  figure > figcaption {
    @apply font-bold text-gray-800 leading-snug md:text-lg pt-1 px-1 md:pt-2;

    > span {
      @apply block text-sm text-gray-400 font-normal leading-tight md:text-base md:mt-1;
    }
  }

  &.--sale figure > figcaption > span {
    @apply font-bold text-primary text-base;
  }
}

/* PDF skeleton */
.horizontal-media-scroller.--caption.--shadow.--skeleton {
  padding-bottom: 2.22rem;

  figure {
    @apply shadow-xl;
  }

  picture {
    @apply pt-8 px-3 pb-6;
  }

  figcaption {
    @apply absolute top-0 text-primary px-3 pt-1;
  }
}
