#sticky-nav-bar {
  @apply bg-primary leading-none sticky bottom-0 flex-shrink-0 px-5 pt-1.5 flex justify-between md:px-10;

  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    @apply bg-opacity-75;
    backdrop-filter: saturate(180%) blur(20px);
  }

  a {
    @apply  text-white flex flex-col items-center px-2.5 pb-2.5 pt-1.5 rounded-t-md md:pb-3.5 md:px-3.5;

    &.active {
      @apply text-white bg-gray-700 bg-opacity-20;
    }

    > span {
      @apply block text-xs md:text-sm md:mt-1 leading-none;
    }

    /* Icons */
    svg {
      @apply w-6 h-6 fill-current md:w-8 md:h-8;
    }
  }
}
