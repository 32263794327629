input.form-input {
  @apply rounded-lg bg-gray-200 border-gray-200 focus:bg-gray-100 focus:shadow-lg;
}
#rating {
  flex-direction: row;
  display: flex;

  .rating-item.active {
    color: gold;
  }

  .btn {
    background-color: #fea30b;
    --tw-border-opacity: 1;
    border-color: rgb(255,255,255);
    border-radius: .5rem;
    border-width: 2px;
    font-weight: 700;
    padding: .375rem .75rem;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgb(255,255,255);
    text-transform: uppercase;
    -webkit-appearance: button;
  }
  .w-56 {
    width: 14rem;
  }

  @media (min-width: 1024px) {

    .btn {
      padding-top: .625rem;
      padding-bottom: .625rem;
    }
  }
}

.stars-1 .fa-star:nth-child(1){
  color: gold;

}

.stars-2 .fa-star:nth-child(1),
.stars-2 .fa-star:nth-child(2) {
  color: gold;

}

.stars-3 .fa-star:nth-child(1),
.stars-3 .fa-star:nth-child(2),
.stars-3 .fa-star:nth-child(3) {
  color: gold;
}


.stars-4 .fa-star:nth-child(1),
.stars-4 .fa-star:nth-child(2),
.stars-4 .fa-star:nth-child(3),
.stars-4 .fa-star:nth-child(4) {
  color: gold;
}

.stars-5 .fa-star, .stars-overlay .fa-star {
  color: gold;
}
.success-message {
  @apply text-primary;
}
.rating-container h3.average-count {
  @apply text-primary;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 2rem;
}
.average-rating {
  display: flex;
  align-items: center;
}
.average-text {
  margin-left: 10px;
  @apply text-primary;
}
.stars-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
}
.star-box {
  display: inline-block;
  position: relative;
}
.rating-container {
  max-width: 480px
}
main section .rating-divider {
  margin: 2rem 0 !important;
}

